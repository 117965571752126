// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "items-module--button--nE9HY";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var content = "items-module--content--OQy2p";
export var images = "items-module--images--ZCcqL";
export var info = "items-module--info--LD2fS";
export var item = "items-module--item--V8dW0";
export var line = "items-module--line---jog3";
export var lines = "items-module--lines--IB406";
export var menu = "items-module--menu--gVKr0";
export var title = "items-module--title--h6cLq";