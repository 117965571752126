import PropTypes from "prop-types";

import { Section, Container, ButtonFlat, getProperty } from "@gh/shared";

import * as styles from "./pricelists.module.scss";

export const PriceLists = ({ data }) => {
  const items = getProperty(data, "items") || [];

  return (
    <Section align="center">
      <Container>
        <div className={styles.items}>
          {items.map((line, index) => {
            const text = getProperty(line, "text");
            const href = getProperty(line, "file.publicURL");

            return (
              <ButtonFlat
                key={href}
                href={href}
                wrapper="a"
                rel="noopener"
                target="_blank"
                download={text}
              >
                {text}
              </ButtonFlat>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

PriceLists.defaultProps = {
  data: {},
};

PriceLists.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
