import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Section, Container, Markdown, Title, Text, ButtonArrow, getProperty } from "@gh/shared";

import * as styles from "./annulation.module.scss";

export const Annulation = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const items = getProperty(data, "items") || [];

  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");

  return (
    <Section align="center" noPaddingTop={true}>
      <Container>
        <Title size="s4" family="f2" weight="w4" transform="uppercase">
          {title}
        </Title>
        <Markdown content={content} />
        <div className={styles.items}>
          {items.map((line, index) => {
            const label = getProperty(line, "label");
            const value = getProperty(line, "value");

            return (
              <div key={index} className={styles.item}>
                <Text size="s3">{label}</Text>
                <Text size="s5">{value}</Text>
              </div>
            );
          })}
        </div>

        <ButtonArrow wrapper={Link} to={linkPath}>
          {linkText}
        </ButtonArrow>
      </Container>
    </Section>
  );
};

Annulation.defaultProps = {
  data: {},
};

Annulation.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    link: PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
