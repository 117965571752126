import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { getProperty } from "@gh/shared";

import "./carousel-simple.module.scss";

export const CarouselSimple = ({ images }) => (
    <Swiper
    spaceBetween={0}
    autoHeight={true}
    grabCursor={true}
    pagination={{
      clickable: true,
      bulletClass: 'carousel-simple-bullet',
      horizontalClass: 'carousel-simple-bullets',
      bulletActiveClass: 'carousel-simple-bullet-active',
    }}
    modules={[Pagination]}
  >
    {images.map((item, index) => (
        <SwiperSlide key={index}>
          <GatsbyImage image={getProperty(item, "childImageSharp.gatsbyImageData")} alt="" />
        </SwiperSlide>
    ))}
  </Swiper>
);

CarouselSimple.defaultProps = {
  images: [],
};

CarouselSimple.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};
