import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { useServices } from "hooks";

import { Meta } from "./common/meta";
import { Intro } from "./rooms/intro";
import { Items } from "./rooms/items";
import { Services } from "./rooms/services";
import { Annulation } from "./rooms/annulation";
import { PriceLists } from "./rooms/pricelists";

const Template = ({ data }) => {
  const services = getProperty(useServices(), "services");

  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.rooms.intro");
  const items = getProperty(data, "markdownRemark.frontmatter.rooms.items");
  const pricelists = getProperty(data, "markdownRemark.frontmatter.rooms.pricelists");
  const annulation = getProperty(data, "markdownRemark.frontmatter.rooms.annulation");

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Items data={items} />
      <PriceLists data={pricelists} />
      <Services data={services} />
      <Annulation data={annulation} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        rooms {
          intro {
            title
            content
          }

          items {
            id
            menu {
              title
              subtitle
              price
            }
            info {
              title
              content
              lines {
                label
                value
              }
              images {
                childImageSharp {
                  gatsbyImageData(width: 520, layout: CONSTRAINED)
                }
              }
              url
            }
          }

          pricelists {
            items {
              file {
                publicURL
              }
              text
            }
          }

          annulation {
            title
            content
            items {
              label
              value
            }
            link {
              text
              path
            }
          }
        }
      }
    }
  }
`;
