import PropTypes from "prop-types";

import { Section, Container, Markdown, Title, Text, /*ArrowIcon,*/ getProperty } from "@gh/shared";

import { BookButtonLarge } from "../common/book-button";
import { CarouselSimple } from "../common/carousel-simple";

import * as styles from "./items.module.scss";

export const Items = ({ data }) => {
  const items = data || [];

  return (
    <Section>
      <Container>
        <nav className={styles.menu}>
          {items.map((item) => {
            const id = getProperty(item, "id");
            const title = getProperty(item, "menu.title");
            const subtitle = getProperty(item, "menu.subtitle");
            const price = getProperty(item, "menu.price");

            return (
              <a key={id} href={`#${id}`} className={styles.item}>
                <Text wrapper="span" transform="uppercase" color="primary">
                  {title}
                </Text>
                <Text wrapper="span" transform="uppercase">
                  {subtitle}
                </Text>
                <Text wrapper="span" size="s7" weight="w7">
                  {price}
                </Text>
                {/*<ArrowIcon className={styles.icon} />*/}
              </a>
            );
          })}
        </nav>
        <div className={styles.info}>
          {items.map((item) => {
            const id = getProperty(item, "id");
            const title = getProperty(item, "info.title");
            const content = getProperty(item, "info.content");
            const lines = getProperty(item, "info.lines");
            const images = getProperty(item, "info.images") || [];
            const url = getProperty(item, "info.url");

            return (
              <div key={id} id={id} className={styles.item}>
                <Title size="s8" className={styles.title}>
                  {title}
                </Title>
                <div className={styles.content}>
                  <Text>
                    <Markdown content={content} />
                  </Text>
                  <div className={styles.lines}>
                    {lines.map((line, index) => {
                      const label = getProperty(line, "label");
                      const value = getProperty(line, "value");

                      return (
                        <div key={index} className={styles.line}>
                          <Text size="s2">{label}</Text>
                          <Text size="s4">{value}</Text>
                        </div>
                      );
                    })}
                  </div>
                  <BookButtonLarge url={url} className={styles.button} />
                </div>
                <div className={styles.images}>
                  <CarouselSimple images={images} />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

Items.defaultProps = {
  data: [],
};

Items.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      menu: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
      }).isRequired,
      info: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        lines: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          })
        ).isRequired,
        images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
